import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginLayout from './layouts/Login';
import LoginView from './views/Login';
import SignupView from './views/Signup';

import AppLayoutWrapper from './layouts/App';
import BoardsPage from './views/Boards';
import AdminsPage from './views/Admins';
import WalletPage from './views/Wallet';
import AdsPage from './views/Ads';
import AdminSettings from './views/AdminSettings';
import AdAnalyticsPage from './views/AdAnalytics';
import VerifyView from './views/Verify';
import ReferralPage from './views/Referral';
import useUserInfoStore from './store/userInfo';
import { UserRole } from './api/admins';

const RootPath = () => {
    const { userInfo } = useUserInfoStore();

    return userInfo?.roles?.includes(UserRole.AGENT) ? <Navigate to="/app/referral"/> : <Navigate to="/app/ads"/>
  };

const routes = [
    {
        path: '/',
        element: <LoginLayout/>,
        children: [
            { path: '/', element: <LoginView/> },
            { path: '/signup', element: <SignupView/> },
        ],
    },
    {
        path: 'app',
        element: <AppLayoutWrapper/>,
        children: [
            { path: 'verify', element: <VerifyView/> },
            { path: 'boards', element: <BoardsPage/> },
            { path: 'referral', element: <ReferralPage/> },
            { path: 'boards/:id', element: <BoardsPage/> },

            { path: 'ads', element: <AdsPage/> },
            { path: 'ads/:id', element: <AdsPage/> },
            { path: 'ad/analytics', element: <AdAnalyticsPage/> },
            { path: 'wallet', element: <WalletPage/> },
            { path: 'advertisers/:id', element: <WalletPage/> },
            { path: 'admins', element: <AdminsPage/> },
            { path: 'admins/:id', element: <AdminsPage/> },
            { path: 'account', element: <AdminSettings/> },
            { path: '/', element: <RootPath /> },
        ],
    },
];

export default routes;
