import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror';
import { menu, options } from '@aeaton/react-prosemirror-config-default';
import { Field } from 'react-final-form';


const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: 6,
        padding: '8px 0',
    },
    label: {
        background: '#fff',
        padding: '0 6px',
    },
}));

interface IProps {
    label: string;
    name: string;
}

const simpleMenu = {
    ...menu,
    blocks: {},
    insert: {},
    history: {
        ...menu.history,
        redo: {
            ...menu.history.redo,
            title: 'Вернуть последнее изменение',
        },
        undo: {
            ...menu.history.undo,
            title: 'Отменить последнее изменение',
        },
    },
    marks: {
        strong: { ...menu.marks.strong, title: 'Жирный' },
        em: { ...menu.marks.em, title: 'Курсив' },
        underline: { ...menu.marks.underline, title: 'Подчеркнутый' },
        strikethrough: { ...menu.marks.strikethrough, title: 'Перечеркнутый' },
        link: { ...menu.marks.link, title: 'Ссылка' },
    },
};

function WysiwygField({ input, label }: any) {
    const classes = useStyles();
    return (
        <HtmlEditor options={options}
                    {...input} render={({ editor, view }: any) => (
            <FormControl variant="outlined" fullWidth className={classes.root} margin="normal">
                <InputLabel shrink className={classes.label}>{label}</InputLabel>
                <MenuBar menu={simpleMenu} view={view}/>
                {editor}
            </FormControl>
        )}/>
    );
}

export default function EditorField({ name, ...rest }: IProps) {
    return (
        <Field name={name} component={WysiwygField} {...rest} />
    );
}
