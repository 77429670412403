import { useEffect, useRef, useState } from 'react';
import { useMyBalance } from '../api/selfSettings';

const POLLING_INTERVAL = 30 * 1000

let timerId: NodeJS.Timeout | null = null;

export const useBalanceWithPolling = (isSuccessPayment: boolean) => {
  const [updated, setIsUpdated] = useState(false)
  const currentBalance = useRef(null)
  const isFetched = useRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ { data: { balance } }, isLoadingBalance, loadBalance ] = useMyBalance();

  useEffect(() => {
      loadBalance();
      isFetched.current = true
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccessPayment && currentBalance.current === null && isFetched.current) {
      currentBalance.current = balance

      timerId = setInterval(() => {
        loadBalance();
      }, POLLING_INTERVAL)
    }

    if (timerId && currentBalance.current !== balance) {
      clearTimeout(timerId)
      setIsUpdated(true)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, isSuccessPayment])


  return [balance, updated]
}