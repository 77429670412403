import React from 'react';
import { IBoardsListRequest } from '../../api/boards';
import Filters from '../../components/Filter';
import { BoolSelect, DateField, Input, Select } from '../../components/Form';
import { IGroup } from '../../api/groups';


export const defaultFilter: IBoardsListRequest = {
    name: undefined,
    updatedAfter: undefined,
    updatedBefore: undefined,
    active: undefined,
    groups: undefined,
};

interface IProps {
    filter: IBoardsListRequest;
    onApply: any;
    groups: IGroup[];
}

export default function BoardsFilter({ filter, onApply, groups }: IProps) {
    return (
        <Filters initial={filter} onSubmit={onApply}>
            <DateField name="updatedAfter" label="Изменен после"/>
            <DateField name="updatedBefore" label="Изменен до"/>
            <Input name="name" label="Название" margin="dense"/>
            <Select name="groups"
                    label="Группы"
                    options={groups.map((g) => ({ value: g.id, label: g.id }))}
                    multi
                    margin="dense"/>
            <BoolSelect name="active" label="Активен" margin="dense"/>
        </Filters>
    );
}
