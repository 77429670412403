import React from 'react';
import { Grid } from '@material-ui/core';
import { required } from '../../validators';
import { Input } from '../../components/Form';


export function LegalEntityData() {
    return (
      <Grid item md={12} xs={12}>
        <Grid container direction="column">
          <Grid item>
            <Input name="legalEntity.address"
              label="Адрес организации"
              type="text"
              validate={required}
            />
          </Grid>
          <Grid item>
            <Input name="legalEntity.inn"
              label="ИНН"
              type="text"
              validate={required}
            />
          </Grid>
          <Grid item>
            <Input name="legalEntity.okpo"
              label="ОКПО"
              type="text"
              validate={required}
            />
          </Grid>
          <Grid item>
            <Input name="legalEntity.ogrn"
              label="ОГРН"
              type="text"
              validate={required}
            />
          </Grid>
          <Grid item>
            <Input name="legalEntity.kpp"
              label="КПП"
              type="text"
              validate={required}
            />
          </Grid>
          <Grid item>
            <Input name="legalEntity.account_number"
              label="Номер счёта"
              type="text"
            />
          </Grid>
          <Grid item>
            <Input name="legalEntity.bank_bik"
              label="БИК банка"
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
    )
}

