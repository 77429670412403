import { TextField } from 'final-form-material-ui';
import { Field } from 'react-final-form';
import React from 'react';


interface IInputProps {
    name: string;
    type?: string;
    label?: any;
    required?: boolean;
    fullWidth?: boolean;
    validate?: any;
    helperText?: string;
    disabled?: boolean;
    inputRef?: any;
    onFocus?: any;
    onBlur?: any;
    onInput?: any;
    multiline?: boolean;
    margin?: 'none' | 'dense' | 'normal';
    className?: any;
    autoComplete?: string;
    autoFocus?: boolean;
    InputProps?: any;
    inputProps?: any;
    placeholder?: string;
    emptyAsNull?: boolean;
}

export default function InputField({ name, type, multiline, margin, emptyAsNull, ...rest }: IInputProps) {
    return (
        <Field name={name}
               type={type || 'text'}
               component={type === 'hidden' ? 'input' : TextField}
               variant="outlined"
               margin={margin || 'normal'}
               fullWidth
               multiline={multiline}
               rows={multiline && 3}
               parse={emptyAsNull ? (value => value.length > 0 ? value : null) : undefined}
               {...rest}
        />
    );
}
