import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
}));

interface IProps {
    className?: any;
    href: string;
    icon: any;
    title: any;
    target?: any;
}

export default function MenuItem({ className, href, icon: Icon, title, target, ...rest }: IProps) {
    const classes: any = useStyles();

    if (target === '_blank') {
        return (
            <ListItem className={clsx(classes.item, className)} disableGutters
                      {...rest}
            >
                <Button className={classes.button} component={Button} onClick={() => window.open(href)}>
                    {Icon && (
                        <Icon className={classes.icon} size="20"/>
                    )}
                    <span className={classes.title}>
                        {title}
                    </span>
                </Button>
            </ListItem>
        );
    }

    return (
        <ListItem className={clsx(classes.item, className)} disableGutters
                  {...rest}
        >
            <Button activeClassName={classes.active} className={classes.button} component={RouterLink} to={href}>
                {Icon && (
                    <Icon className={classes.icon} size="20"/>
                )}
                <span className={classes.title}>
                    {title}
                </span>
            </Button>
        </ListItem>
    );
};
