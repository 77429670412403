import { create } from 'zustand';

// simple store to avoid refetching user info every time

interface IUserInfoStore {
  userInfo: null | Record<string, any>;
  setUserInfo: (data: Record<string, any>) => void;
}

const useUserInfoStore = create<IUserInfoStore>((set) => ({
  userInfo: null,
  setUserInfo: (userInfo) => set({ userInfo })
}));

export default useUserInfoStore;