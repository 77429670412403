import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { Box, FormControl, Input, InputLabel, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useBankPayment, useTopUpMyBalance } from '../api/payment';
import { useMyInfo } from '../api/selfSettings';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 6,
            boxShadow: theme.shadows[5],
            padding: '28px 30px',
            minWidth: 300,
            minHeight: 400,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        subtitle: {
            fontSize: 13,
            marginTop: 10,
        },
        form: {
            marginTop: 'auto',
            marginBottom: '20px',
        },
        formText: {
            fontSize: 13,
            marginBottom: 'auto',
        },
        button: {
            width: '100%',
        },
    }),
);

export const AddMoneyModal: React.FC<any> = (props) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>();

    const [ { data }, loading, load ] = useMyInfo();

    const isLegalEntity = data?.advertiser?.isLegalEntity

    const [ open, setOpen ] = React.useState(false);
    const [ amount, setAmount ] = useState<number>();

    const handleOpen = () => {
        setOpen(true);
        setTimeout(() => inputRef?.current?.focus(), 100);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            // @ts-ignore
            amount > 0 && createPayment({ amount })
        }
    }

    const [ createBankPayment, isCreatingBankPayment ] = useBankPayment((res: any) => {
        if (res.success) {
            window.location = res.data;
            setOpen(false);
        } else {
            alert('Произошла ошибка, попробуйте позднее');
        }
    })

    const [ createPayment, creatingPayment ] = useTopUpMyBalance((res: any) => {
        if (res.success) {
            window.location = res.data.confirmation_url;
            setOpen(false);
        } else {
            alert('Произошла ошибка, попробуйте позднее');
        }
    });

    const handleClickPay = () => {
        if (isLegalEntity) {
            return createBankPayment({ amount })
        }

        return createPayment({ amount })
    }

    useEffect(() => {
        load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <label onClick={handleOpen}>
                {props.children}
            </label>

            <Modal aria-labelledby="transition-modal-title"
                   aria-describedby="transition-modal-description"
                   className={classes.modal}
                   open={open}
                   onClose={handleClose}
                   closeAfterTransition
                   BackdropComponent={Backdrop}
                   BackdropProps={{
                       timeout: 500,
                   }}>
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography variant="h3" component="h2">
                            Пополнение баланса
                        </Typography>

                        <Typography className={classes.subtitle} component="h2">
                            Деньги поступят на счет текущего аккаунта в баллах
                        </Typography>

                        <FormControl fullWidth className={classes.form}>
                            <InputLabel htmlFor="standard-adornment-amount">
                                Сумма в рублях, 1 рубль = 1 балл
                            </InputLabel>
                            <Input value={amount}
                                   onChange={(e) => setAmount((+e.target.value || '') as number)}
                                   onKeyDown={handleKeyDown}
                                   type="number"
                                   required
                                   inputRef={inputRef}
                                   id="standard-adornment-amount"/>
                        </FormControl>
                        <Typography className={classes.formText} component="h2">
                            Принимаем карты всех банков РФ
                        </Typography>
                        <Button onClick={() => {
                            // @ts-ignore
                            amount > 0 && handleClickPay();
                        }}
                                disabled={creatingPayment || isCreatingBankPayment || loading}
                                className={classes.button}
                                startIcon={<CreditCardIcon/>}
                                size="medium"
                                variant="contained"
                                color="primary">
                            Перейти к оплате
                        </Button>
                        {!isLegalEntity && !loading && (
                            <Box mt={3}>
                                <Link
                                    className="mt-3"
                                    component={RouterLink}
                                    to="/app/account?legalWarning=true"
                                >
                                    Оплата по счету для юридических лиц и ИП
                                </Link>
                            </Box>
                        )}
                    </div>
                </Fade>
            </Modal>
        </>
    );
};
