import { colors, createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';


const theme = createMuiTheme({
    palette: {
        background: {
            default: colors.common.white,
            paper: colors.common.white,
        },
        primary: {
            main: colors.teal[900],
        },
        secondary: {
            main: colors.red[500],
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
        },
        success: {
            main: colors.green[500],
        },
        error: {
            main: colors.red[500],
        },
    },
    overrides: {
        MuiInput: {
            root: {
                '& input[type=number]': {
                    '-moz-appearance': 'textfield !important',
                    '&::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none !important',
                        margin: 0,
                    },
                    '&::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none !important',
                        margin: 0,
                    },
                },
            },
        },
        MuiButton: {
            root: {
                '&.deleteButton': {
                    backgroundColor: colors.red[700],
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: colors.red[900],
                    },
                },
                '&.successButton': {
                    backgroundColor: colors.green[700],
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: colors.green[900],
                    },
                },
            },
        },
    },
    // @ts-ignore
    shadows,
    typography,
});

export default theme;
