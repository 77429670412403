import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import WalletList from './WalletList';
import WalletFilter, { defaultFilter } from './WalletFilter';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import useFilter from '../../hooks/useFilter';
import { useWalletList } from '../../api/payment';


function AdvertisersPage() {
    const classes = useGlobalStyles();
    const [ { items }, loading, load ] = useWalletList();
    const [ setFilter, filter ] = useFilter(defaultFilter);

    useEffect(() => {
        load(filter);
    }, [ filter ]);

    return (
        <Page className={classes.root} title="Пополнения и списания">
            <WalletFilter filter={filter} onApply={setFilter}/>
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Loader loading={loading}>
                        <WalletList advertisers={items}/>
                    </Loader>
                </Box>
            </Container>
        </Page>
    );
}


export default AdvertisersPage;
