import { useEffect } from 'react';
import useUserInfoStore from '../../store/userInfo';
import { useMyInfo } from '../../api/selfSettings';
import AppLayout from './AppLayout';
import AppLayoutSkeleton from './skeletons/AppLayout';

export default function AppLayoutWrapper() {
    const { userInfo, setUserInfo } = useUserInfoStore()
    const [ { data },, load ] = useMyInfo(null);

    useEffect(() => {
        load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!userInfo && data) {
            setUserInfo(data)
        }
    }, [data, setUserInfo, userInfo])

    return userInfo ? <AppLayout /> : <AppLayoutSkeleton />
};
