import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field } from 'react-final-form';
import { KeyboardDateTimePicker } from '@material-ui/pickers';


interface IDateFieldProps {
    name: string;
    label?: string;
    max?: Date | number;
    min?: Date | number;
    validate?: any;
    margin?: boolean;
    disabled?: boolean;
}

interface IStylesProps {
    margin?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: ({ margin }: IStylesProps) => ({
        marginTop: margin ? theme.spacing(2) : 'auto',
        '& .MuiInputAdornment-positionEnd': {
            marginRight: '-20px',
        },
    }),
}));

const DateField = ({ name, label, min, max, validate, margin, disabled }: IDateFieldProps) => {
    const classes = useStyles({ margin });
    return (
        <Field name={name} render={({ input: { onChange, value } }) => (
            <KeyboardDateTimePicker onChange={onChange}
                                    value={value || null}
                                    maxDate={max}
                                    minDate={min}
                                    format="yyyy-MM-dd HH:mm"
                                    variant="inline"
                                    autoOk
                                    label={label}
                                    inputVariant="outlined"
                                    className={classes.root}
                                    ampm={false}
                                    disabled={disabled}/>
        )} format={(v) => (v)} validate={validate}/>
    );
};

export default DateField;
