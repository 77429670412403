import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import DocsIcon from '@material-ui/icons/ImportContacts';
import MenuItem from '../Menu/MenuItem';
import NoteIcon from '@material-ui/icons/Note';
import DescriptionIcon from '@material-ui/icons/Description';
import { Skeleton } from '@mui/material';


const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 310,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    menuItems: {
      marginLeft: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      height: '100%'
    }
}));

export default function MenuSkeleton() {
    const classes = useStyles();

    return (
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
            <div style={{ margin: 16 }}>
              <Skeleton variant="rectangular" width={225} height={210} />
            </div>
            <div className={classes.menuItems}>
              <Skeleton variant="rectangular" width={225} height={24} />
              <Skeleton variant="rectangular" width={225} height={24} />
              <Skeleton variant="rectangular" width={225} height={24} />
              <Skeleton variant="rectangular" width={225} height={24} />
            </div>
            <div style={{ padding: 15 }}>
                <MenuItem target="_blank"
                          href={'https://storage.yandexcloud.net/citytv-media/docs/%D0%9F%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D0%B0%D1%8F%20%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D0%BE%D0%BC.pdf'}
                          title={<span style={{ fontSize: 10 }}>Договор оферта</span>}
                          icon={DocsIcon}/>
                <MenuItem target="_blank"
                          href={'https://storage.yandexcloud.net/citytv-media/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'}
                          title={<span style={{ fontSize: 10 }}>Политика конфиденциальности</span>}
                          icon={DescriptionIcon}/>
                <MenuItem target="_blank"
                          href={'https://storage.yandexcloud.net/citytv-media/docs/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D1%80%D0%B5%D1%84%D0%B5%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B.pdf'}
                          title={<span style={{ fontSize: 10 }}>Политика обработки данных</span>}
                          icon={NoteIcon}/>

            </div>
            <div style={{ minHeight: 180, marginLeft: 16 }}>
              <Skeleton variant="rectangular" width={225} height={165} />
            </div>
        </Drawer>
    );
};
