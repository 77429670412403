import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import SettingsIcon from '@material-ui/icons/Settings';
import UsersIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TimelineIcon from '@material-ui/icons/Timeline';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DocsIcon from '@material-ui/icons/ImportContacts';
import MenuItem from './MenuItem';
import NoteIcon from '@material-ui/icons/Note';
import DescriptionIcon from '@material-ui/icons/Description';
import { useMyInfo } from '../../../api/selfSettings';
import { UserRole } from '../../../api/admins';
import { BalanceItem } from './BalanceItem';
import { ReferralLink } from './ReferralLink';


interface IMenuItem {
    href: string;
    icon: any;
    title: string;
    roles: UserRole[];
    target?: any;
}

const items: IMenuItem[] = [
    {
        href: '/app/ads',
        icon: MonetizationOnIcon,
        title: 'Моя реклама',
        roles: [UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER, UserRole.LAWYER, UserRole.NEWS_MANAGER, UserRole.USER],
    },
    {
        href: '/app/ad/analytics',
        icon: TimelineIcon,
        title: 'Рекламная аналитика',
        roles: [UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER, UserRole.LAWYER, UserRole.NEWS_MANAGER, UserRole.USER],
    },
    {
        href: '/app/wallet',
        icon: AccountBalanceWalletIcon,
        title: 'Пополнения и списания',
        roles: [],
    },
    {
        href: '/app/account',
        icon: SettingsIcon,
        title: 'Настройки',
        roles: [],
    },
    {
        href: '/app/referral',
        icon: UsersIcon,
        title: 'Реферальная программа',
        roles: [],
    },
];

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 310,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
}));

interface IProps {
    onMobileClose: any;
    openMobile: boolean;
    balance: number;
    blockedAmount: number;
}

export default function Menu({ onMobileClose, openMobile = false, balance = 0, blockedAmount = 0 }: IProps) {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ location.pathname ]);

    const [ { data: { id, roles } }, loading, load ] = useMyInfo();

    useEffect(() => {
        load();
    }, []);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box p={2}>
                <List>
                    {!loading && items.map((item) => (
                        item.roles.length
                            ? (item.roles.filter((role) => {
                                return (roles as string[]).includes(role.toString());
                            }).length > 0 && (
                                <MenuItem href={item.href} key={item.title} title={item.title} icon={item.icon}/>))
                            : (<MenuItem href={item.href} key={item.title} title={item.title} icon={item.icon}/>)
                    ))}
                </List>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left"
                        classes={{ paper: classes.mobileDrawer }}
                        onClose={onMobileClose}
                        open={openMobile}
                        variant="temporary">
                    <BalanceItem balance={balance} blockedAmount={blockedAmount} shouldShowAddBalance={!roles?.includes(UserRole.AGENT)} />
                    {content}
                    <ReferralLink id={id}/>
                </Drawer>
            </Hidden>

            <Hidden mdDown>
                <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
                    <BalanceItem balance={balance} blockedAmount={blockedAmount} shouldShowAddBalance={!roles?.includes(UserRole.AGENT)} />
                    {content}
                    <div style={{ padding: 15 }}>
                        <MenuItem target="_blank"
                                  href={'https://storage.yandexcloud.net/citytv-media/docs/%D0%9F%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D0%B0%D1%8F%20%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D0%BE%D0%BC.pdf'}
                                  title={<span style={{ fontSize: 10 }}>Договор оферта</span>}
                                  icon={DocsIcon}/>
                        <MenuItem target="_blank"
                                  href={'https://storage.yandexcloud.net/citytv-media/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'}
                                  title={<span style={{ fontSize: 10 }}>Политика конфиденциальности</span>}
                                  icon={DescriptionIcon}/>
                        <MenuItem target="_blank"
                                  href={'https://storage.yandexcloud.net/citytv-media/docs/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D1%80%D0%B5%D1%84%D0%B5%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B.pdf'}
                                  title={<span style={{ fontSize: 10 }}>Политика обработки данных</span>}
                                  icon={NoteIcon}/>

                    </div>
                    <ReferralLink id={id}/>
                </Drawer>
            </Hidden>
        </>
    );
};
