import _ from 'lodash';
import { AuthStore } from '../../store/auth';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { UserRoleType } from '../../api/admins';
import { AdStatus } from '../../api/ads';


type RoleProps = {
    children: ReactElement | ReactElement[],
    roles: UserRoleType[],
    adStatus?: AdStatus,
    onStatuses?: { [key in AdStatus]?: (UserRoleType | '*')[] }
}

export const RbacRole = observer((props: RoleProps) => {
    const { roles, adStatus, onStatuses } = props;

    let allowed = _.intersectionBy(roles, AuthStore.roles).length > 0;

    if (onStatuses && adStatus) {
        const statusCheck = allowed && onStatuses[adStatus];
        const intersect = _.intersectionBy(onStatuses[adStatus], AuthStore.roles);

        allowed = !onStatuses[adStatus]?.includes('*') ? !!statusCheck && (intersect.length > 0) : true;
    }

    return allowed ? <>{props.children}</> : <></>;
});
