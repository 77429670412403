import React, { useEffect, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import { ButtonGroup } from '@material-ui/core';

import { useGlobalStyles } from '../../theme/useGlobalStyles';

import { useKiosksList } from '../../api/kiosks';
import { useAdAnalyticsList, useAdsList } from '../../api/ads';

import useFilter from '../../hooks/useFilter';
import Loader from '../../components/Loader';

import AdAnalyticsFilter, { defaultFilter } from './AdAnalyticsFilter';
import AdAnalyticsChart from './AdAnalyticsChart';
import AdAnalyticsCards from './AdAnalyticsCards';
import { AdAnalyticsReport } from './AdAnalyticsReport';


type ModeType = 'line' | 'column' | undefined

const AdAnalyticsPage = () => {
    const classes = useGlobalStyles();

    const componentRef = useRef<HTMLDivElement>(null);

    const [ mode, changeMode ] = useState('line');
    const [ setFilter, filter ] = useFilter(defaultFilter);

    const [ { items: kiosks }, loadingKiosks, loadKiosks ] = useKiosksList();
    const [ { items: ads }, loadingAds, loadAds ] = useAdsList();
    const [ { items: data }, loadingAdAnalytics, loadAdAnalytics ] = useAdAnalyticsList();
    const [ { items: reportData }, loadingReportData, loadReportData ] = useAdAnalyticsList();

    let total = 0;
    data.forEach((item: { overall: number }) => total += item.overall);

    const average = data.length ? total / data.length : 0;
    const onClickChangeMode = () => changeMode(mode == 'column' ? 'line' : 'column');

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        loadKiosks({});
        loadAds();
        loadAdAnalytics(filter);
        loadReportData({ ...filter, groupByAdId: true });
    }, [ filter ]);

    const chartData: object[] = [];

    if (mode === 'column') {
        data.forEach((item: any) => {
            chartData.push({
                date: item.date,
                count: item.standard,
                type: 'standard',
            });

            chartData.push({
                date: item.date,
                count: item.rush,
                type: 'rush',
            });
        });

    } else {
        data.forEach((item: any) => {
            chartData.push({
                date: item.date,
                total: item.overall,
            });
        });
    }

    const action = (
        <ButtonGroup color="primary" className="mw-full">
            <Button variant="outlined" color="primary" onClick={() => onClickChangeMode()}>
                {mode === 'line' ? 'По типу времени' : 'Все показы рекламы'}
            </Button>
            <Button variant="outlined" color="primary" onClick={handlePrint}>
                <span className="mobile-hidden"><PrintIcon style={{ marginRight: 8 }}/></span>
                <span>Печать отчета</span>
            </Button>
        </ButtonGroup>
    );

    return (
        <Page className={classes.root} title="Аналитика показов рекламы" action={action}>
            <div className="filters-wrapper">
                <AdAnalyticsFilter filter={filter} kiosks={kiosks} ads={ads} onApply={setFilter}/>
            </div>
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Loader loading={loadingAdAnalytics}>
                        <AdAnalyticsCards count={data.length} total={total} average={Math.floor(average)}/>
                        <AdAnalyticsChart data={chartData} mode={mode as ModeType}/>
                        <div style={{ margin: '30px 0' }}/>
                        {/* @ts-ignore*/}
                        <AdAnalyticsReport ref={componentRef}
                                           data={reportData}
                                           from={filter.periodFrom}
                                           to={filter.periodTo}/>
                    </Loader>
                </Box>
            </Container>
        </Page>
    );
};


export default AdAnalyticsPage;
