export const required = (value: any) => (value !== undefined && value !== null && value.toString().length > 0 ? undefined : 'Обязательное поле');

export const composeValidators = (...validators: any[]) => (value: any) => validators.reduce(
    (error, validator) => error || validator(value), undefined,
);

export const isOTP = (value: any) => value && (isNaN(value) || value.length !== 6) ? 'Код из 6 цифр' : undefined;

const emailRegexp = /^(([^<>()[\]\\.,;:!&%#'\s@"]+(\.[^<>()[\]\\.,;:!&%#'\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value?: string) => !value || emailRegexp.test(value.trim().toLowerCase()) ? undefined : 'Неправильный email';

const ipRegexp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const isIP = (value?: string) => !value || ipRegexp.test(value.trim()) ? undefined : 'Неправильный IP-адрес';

const groupRegexp = /^([a-zA-Z0-9а-яА-Я \-_]+)$/;

export const isGroupId = (value?: string) => !value || groupRegexp.test(value.trim().toLowerCase()) ? undefined : 'Неправильное ID группы. Используйте буквы, цифры и _-';

export const isURL = (value: any) => {
    if (!value) return;
    const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+!@#$%^&*()_;:-]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !pattern.test(value) ? 'Невалидный адрес ссылки' : undefined;
};

export const isImageUrl = (value: any) => {
    if (!value) return;
    const pattern = /\.(jpeg|jpg|gif|png)$/;
    return !pattern.test(value) ? 'Невалидный адрес изображения' : undefined;
};

export const isVideoUrl = (value: any) => {
    if (!value) return;
    const pattern = /\.(mov|mp4|avi)$/;
    return !pattern.test(value) ? 'Невалидный адрес видео' : undefined;
};

export const isUrlsList = (value: any) => {
    if (!value || !value.length) return;
    const urls = value.split('\n').filter((s: string) => s.length > 0);
    const errs: string[] = [];
    for (let i = 0, l = urls.length; i < l; i++) {
        if (!!isURL(urls[i])) {
            errs.push(urls[i]);
        }
    }
    return errs.length ? 'Содержит невалидные адреса: ' + errs.join(', ') : undefined;
};
