import Api, { useFetchApi, useUpdateApi } from './index';


export function useMyInfo(initialData: any = {}) {
    return useFetchApi(
        async () => Api.post('/user/self', {}),
        { data: initialData },
        true,
    );
}

export function useMyBalance() {
    return useFetchApi(
        async () => Api.get('/user/self/balance', {}),
        { data: { balance: 0 } },
        true,
    );
}

export function useGetMyReferralGraph() {
    return useFetchApi(
        async () => Api.get('/user/referral'),
        { items: [] },
        true,
    );
}

export function useSetOtp(cb: any) {
    return useUpdateApi(
        async (data: any) => Api.post('/user/self/totp', data),
        cb,
    );
}

export function useChangePassword(cb: any) {
    return useUpdateApi(
        async (data: any) => Api.post('/user/self/password', data),
        cb,
    );
}
