import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useMyInfo } from '../../api/selfSettings';
import Api from '../../api';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';


const DisabledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  backdrop-filter: blur(2px);
  box-shadow: rgb(19, 47, 76) 0px -1px 1px inset;
  background-color: rgba(10, 25, 41, 0.12);

  .verify-text {
    background: white;
    border-radius: 10px;
    padding: 18px 22px;
    margin-bottom: 20px;
  }
`;

const useStyles = makeStyles({
    button: {},
});


const VerifyView = () => {
    const classes = useGlobalStyles();
    const styles = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const [ { data: { email, active } }, loading, load ] = useMyInfo();

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (!loading && active) {
            window.location.pathname = 'app/ads';
        }
    }, [ loading ]);

    const onClick = async () => {
        const { data: { data: { active } } } = await Api.post('/user/self', {});

        if (active) {
            window.location.pathname = 'app/ads';
        } else {
            enqueueSnackbar('Email еще не подтвержден', { variant: 'error' });
        }
    };

    return (
        <Page className={classes.root} title="Login" hidePageTitle>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="xs">
                    <DisabledWrapper>
                        <span className="verify-text">
                            Для продолжения, пожалуйста, подтвердите email<br/><br/>
                            Мы уже отправили ссылку на <b>{email}</b>
                        </span>

                        <Button onClick={onClick}
                                className={styles.button}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large">
                            Я подтвердил(а), продолжить
                        </Button>
                    </DisabledWrapper>
                </Container>
            </Box>
        </Page>
    );
};

export default VerifyView;
