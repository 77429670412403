import Api, { IBaseModel, useFetchApi, useUpdateApi } from './index';
import { IAdmin } from './admins';


export interface IAdvertiserContact {
    name?: string;
    phone?: string;
    email?: string;
}

export const AdvertiserContactsNames = {
    name: 'Имя',
    phone: 'Телефон',
    email: 'Email',
};

export interface IAdvertiser extends IBaseModel {
    name: string;
    email: string;
    description?: string;
    admins: Partial<IAdmin>[];
    contacts: IAdvertiserContact[];
    ads: any[]; // TODO change to AD model
    active: boolean;
    apiKey: string;
    isLegalEntity: boolean;
    legalEntity: null | ILegalEntity
}

export interface ILegalEntity {
    address: string;
    inn: string;
    okpo: string;
    ogrn: string;
    kpp: string;
    account_number: string | null;
    bank_bik: string | null;
}

export interface IAdvertisersListRequest {
    name?: string;
    email?: string;
    active?: boolean;
}

export interface IUpdateAdvertiserAdminsRequest {
    id: number;
    admins: number[];
}

export function useAdvertisersList() {
    return useFetchApi(
        async (query: IAdvertisersListRequest) => Api.post('/client/list', query),
        { items: [] },
    );
}

export function useAdvertiserCreate(cb: any) {
    return useUpdateApi(
        async (data: IAdvertiser) => Api.post('/client/create', data),
        cb,
    );
}

export function useAdvertiserUpdate(cb: any) {
    return useUpdateApi(
        async (data: IAdvertiser) => Api.post('/client/update', data),
        cb,
    );
}

export function useAdvertiserAdminsUpdate(cb: any) {
    return useUpdateApi(
        async (data: IUpdateAdvertiserAdminsRequest) => Api.post('/client/updateAdvertiserAdmins', data),
        cb,
    );
}
