import React, { createRef } from 'react';
import { useRoutes } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import theme from './theme';
import routes from './routes';

function App() {
    const router = useRoutes(routes);
    const notistackRef: any = createRef();
    const onClickDismiss = (key: any) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <SnackbarProvider anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} hideIconVariant={false} ref={notistackRef} action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <CloseIcon style={{ color: 'white' }}/>
                    </IconButton>
                )}>
                    {router}
                </SnackbarProvider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}

export default App;
