import Api from '../api';

import { makeAutoObservable } from 'mobx';
import { UserRoleType } from '../api/admins';


class Auth {
    constructor() {
        makeAutoObservable(this);
        (() => this.fetchRoles())();
    }

    public roles: UserRoleType[] = [];

    async fetchRoles() {
        const { data } = await Api.post('/user/self', {});

        this.roles = data.data.roles;
    }
}

const AuthStore = new Auth();

export { AuthStore };
