import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import SignupForm from './forms/Signup';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useLocation } from 'react-router';
import Cookies from 'js-cookie';


const Signup = () => {
    const { search } = useLocation();

    const classes = useGlobalStyles();

    const usparams = new URLSearchParams(search)
    const ref = usparams.get('ref');
    const isAgentSignup = usparams.get('agent')

    if (ref) {
        Cookies.set('ref', ref);
    }

    if (ref && isAgentSignup) {
        Cookies.set('agentSignup', 'true')
    }

    return (
        <Page className={classes.root} title="Login" hidePageTitle>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="xs">
                    <SignupForm/>
                </Container>
            </Box>
        </Page>
    );
};

export default Signup;
