import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import { authenticator } from 'otplib';
import qrcode from 'qrcode';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import { Input } from '../../components/Form';
import { composeValidators, isOTP, required } from '../../validators';
import { useChangePassword, useMyInfo, useSetOtp } from '../../api/selfSettings';
import AdvertiserContacts from '../Advertiser/details/Contacts';
import { useAdvertiserUpdate } from '../../api/advertisers';
import CheckboxField from '../../components/Form/CheckboxField';
import { LegalEntityData } from '../LegalEntity/LegalEntityData';
import { useLocation } from 'react-router';
import { Alert } from '@material-ui/lab';
import { UserRole } from '../../api/admins';


const isCodeAccepted = (v: string, s: string) => {
    try {
        const isValid = authenticator.check(v, s);
        console.log(isValid, v, s);
        if (!isValid) return 'Неверный код';
    } catch (e: any) {
        console.log(e);
        return e.toString();
    }
};

export default function AdminSettings() {
    const [ { data }, loading, load ] = useMyInfo();
    const { search } = useLocation()
    const [ secret, setSecret ] = useState<string | undefined>();
    const [ qrImage, setQrImage ] = useState<string | undefined>();
    const [ qrCodeErr, setQrCodeErr ] = useState(false);
    const [ pwdChangeErr, setPwdChangeErr ] = useState(false);
    const [ setOTP, settingOTP ] = useSetOtp((res: any) => {
        if (res.success) {
            load();
        }
    });

    const shouldShowLegalWarning = search.includes('legalWarning')

    const [ saveAdvertiser, savingAdvertiser ] = useAdvertiserUpdate((res: any) => {
        window.location.href = window.location.href.split('?')[0];
    });

    const [ changePassword, passwordChanging ] = useChangePassword((res: any) => {
        if (!res.success) {
            setPwdChangeErr(true);
        }
    });

    const [ { data: { advertiser, roles } }, loadingMyInfo, loadMyInfo ] = useMyInfo();


    useEffect(() => {
        load();
        loadMyInfo();
    }, []);

    useEffect(() => {
        if (!data.totp && data.email) {
            const s = authenticator.generateSecret();
            setSecret(s);
            const otpauth = authenticator.keyuri(data.email, `CTV Admin (${data.email})`, s);
            qrcode.toDataURL(otpauth, (err, image) => {
                if (err) return setQrCodeErr(true);
                setQrImage(image);
            });
        } else {
            setSecret(undefined);
            setQrImage(undefined);
        }
    }, [ data.totp, data.email ]);

    return (
        <Page title="Настройки аккаунта">
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Box mb={3}>
                        {shouldShowLegalWarning && (
                            <Alert severity="warning">
                                Для оплаты по реквизитам, пожалуйста, заполните профиль юридического лица
                            </Alert>
                        )}
                    </Box>
                    <Loader loading={loading}>
                        <Card>
                            <CardHeader title={data.email}/>
                            <Divider/>
                            <CardContent>
                                <Grid container spacing={7}>
                                    {!roles?.includes(UserRole.AGENT) && (
                                        <Grid item md={6} xs={12}>
                                            <Grid container direction="column" spacing={4}>
                                            <Grid item>
                                                <Typography variant="h5" align="left">Настройка рекламодателя</Typography>
                                                <Form onSubmit={saveAdvertiser}
                                                    initialValues={{ ...advertiser, ...(shouldShowLegalWarning ? { isLegalEntity: true } : null) }}
                                                    render={({ handleSubmit, form, values }) => (
                                                        <form onSubmit={(e) => {
                                                            // @ts-ignore
                                                            handleSubmit(e).then(() => {
                                                                // @ts-ignore
                                                                form.restart();
                                                            });
                                                        }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item md={12} xs={12}>
                                                                    <Input name="name"
                                                                            label="Название организации"
                                                                            validate={required}
                                                                            type="text"/>
                                                                </Grid>
                                                                <Grid item md={12} xs={12}>
                                                                    <Input name="apiKey"
                                                                            disabled
                                                                            label="API ключ"
                                                                            type="text"/>
                                                                </Grid>
                                                                <Grid item md={12} xs={12}>
                                                                    <CheckboxField
                                                                        name="isLegalEntity"
                                                                        label="Юридическое лицо"
                                                                    />
                                                                </Grid>
                                                                {values.isLegalEntity && (
                                                                    <LegalEntityData />
                                                                )}
                                                            </Grid>
                                                            <Box my={2}>
                                                                <Button type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="large"
                                                                        disabled={savingAdvertiser}>
                                                                    Сохранить
                                                                </Button>
                                                            </Box>
                                                        </form>
                                                    )}/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h5" align="left">Контакты рекламодателя</Typography>
                                                    <AdvertiserContacts advertiser={advertiser}
                                                                        onSave={(data: any) => saveAdvertiser(data)}
                                                                        saving={savingAdvertiser}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item md={6} xs={12}>
                                        <Grid item>
                                                <Typography variant="h4" align="center">Смена пароля</Typography>
                                                <Form onSubmit={changePassword}
                                                    initialValues={{ oldPassword: null, newPassword: null }}
                                                    render={({ handleSubmit, form }) => (
                                                        <form onSubmit={(e) => {
                                                            // @ts-ignore
                                                            handleSubmit(e).then(() => {
                                                                // @ts-ignore
                                                                form.restart();
                                                            });
                                                        }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item md={6} xs={12}>
                                                                    <Input name="oldPassword" label="Текущий пароль"
                                                                            validate={required}
                                                                            type="password"/>
                                                                </Grid>
                                                                <Grid item md={6} xs={12}>
                                                                    <Input name="newPassword"
                                                                            label="Новый пароль"
                                                                            validate={required}
                                                                            type="password"/>
                                                                </Grid>
                                                            </Grid>
                                                            <Box my={2}>
                                                                <Button type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="large"
                                                                        disabled={passwordChanging}>
                                                                    Изменить пароль
                                                                </Button>
                                                            </Box>
                                                        </form>
                                                    )}/>
                                            </Grid>
                                        <Typography variant="h4" align="center">Двухфакторная авторизация</Typography>
                                        {data.totp
                                            ? (
                                                <Form onSubmit={(values) => setOTP({ ...values, totp: false })}
                                                      initialValues={{ otp: null }}
                                                      render={({ handleSubmit }) => (
                                                          <form onSubmit={handleSubmit}>
                                                              <Grid container spacing={2}>
                                                                  <Grid item md={12}>
                                                                      <Input name="otp"
                                                                             type="number"
                                                                             label="OTP код"
                                                                             validate={composeValidators(required, isOTP)}/>
                                                                  </Grid>
                                                              </Grid>
                                                              <Box my={2}>
                                                                  <Button type="submit"
                                                                          variant="contained"
                                                                          color="secondary"
                                                                          size="large"
                                                                          disabled={settingOTP}>
                                                                      Отключить двухфакторную авторизацию
                                                                  </Button>
                                                              </Box>
                                                          </form>
                                                      )}/>
                                            ) : (
                                                <>
                                                    {qrImage && (
                                                        <Typography align="center">
                                                            <img width={250} height={250} src={qrImage} alt=""/>
                                                        </Typography>
                                                    )}
                                                    {qrCodeErr
                                                        && <Typography variant="body2" color="secondary" align="center">Не
                                                            удалось сгенерировать QR-код</Typography>}
                                                    {secret && (
                                                        <Form onSubmit={setOTP}
                                                              initialValues={{ secret, code: null }}
                                                              render={({ handleSubmit }) => (
                                                                  <form onSubmit={handleSubmit}>
                                                                      <Input name="secret"
                                                                             label="Секретный ключ"
                                                                             disabled
                                                                             margin="normal"/>
                                                                      <Input name="code"
                                                                             label="OTP код"
                                                                             type="number"
                                                                             validate={composeValidators(required, isOTP, (v: string) => isCodeAccepted(v, secret))}
                                                                             margin="normal"/>
                                                                      <Box my={2}>
                                                                          <Button type="submit"
                                                                                  variant="contained"
                                                                                  color="primary"
                                                                                  size="large"
                                                                                  disabled={settingOTP}>
                                                                              Включить двухфакторную авторизацию
                                                                          </Button>
                                                                      </Box>
                                                                  </form>
                                                              )}/>
                                                    )}
                                                </>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Loader>
                </Box>
            </Container>
        </Page>
    );
}
