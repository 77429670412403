import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BoolChip from '../../components/UI/BoolChip';
import { IAdmin, UserRoleName } from '../../api/admins';


interface IProps {
    users: IAdmin[];
    onRowClick: any;
}

export default function AdminsList({ users, onRowClick }: IProps) {
    return (
        <Card>
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Роли</TableCell>
                                <TableCell>Активен</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((u) => (
                                <TableRow hover key={u.id} onClick={() => onRowClick(u.id)}>
                                    <TableCell>{u.id}</TableCell>
                                    <TableCell>{u.email}</TableCell>
                                    <TableCell>{u.roles.map((r) => UserRoleName[r]).join(', ')}</TableCell>
                                    <TableCell>
                                        <BoolChip value={u.active}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};
