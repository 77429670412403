import React, { useRef, useState } from 'react';
import {
    CardWrapper,
    Divider,
    FileMetaData,
    FilePreviewContainer,
    FileUploadContainer,
    FormField,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    RemoveFileIcon,
} from './file-upload.styles';

import { Button, Card, Typography } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';

import byteSize from 'byte-size';

import { loadImage, loadVideo } from '../../api/ads';
import { useStyles } from '../../views/Ads/details/Media';


interface IProps {
    labelToUpload?: string;
    label?: string;
    labelUploaded?: string;
    updateFilesCb?: Function;
    multiple?: boolean;
    disabled?: boolean;
    accept?: string;
    children?: any;
    preview?: any;
}


const convertNestedObjectToArray = (nestedObj: any) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytes = (bytes: number) => {
    const { value, unit } = byteSize(bytes);

    return `${value} ${unit}`;
};

export const FileUpload = (props: IProps) => {
    const {
        label,
        labelToUpload,
        labelUploaded,
        children,
        preview,
        updateFilesCb,
        multiple,
        disabled,
        accept,
    } = props;

    const fileInputField = useRef(null);

    const [ files, setFiles ] = useState<any>({});
    const [ hasPreview, setHasPreview ] = useState<any>(!!preview);

    const classes = useStyles();

    const hasFiles = Object.keys(files).length;

    const handleUploadBtnClick = () => {
        // @ts-ignore
        fileInputField?.current?.click();
    };

    const addNewFiles = async (newFiles: any) => {
        for (let file of newFiles) {

            if (file.type.includes('video/')) {
                const video = await loadVideo(file);

                file.meta = {
                    size: file.size,
                    duration: video.duration,
                    height: video.videoHeight,
                    width: video.videoWidth,
                };
            } else if (file.type.includes('image/')) {
                const image = await loadImage(file);

                file.meta = {
                    size: file.size,
                    height: image.naturalHeight,
                    width: image.naturalWidth,
                };
            }

            if (!multiple) {
                return { file };
            }
        }

        return { ...files };
    };

    const callUpdateFilesCb = (files: any) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb && updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = async (e: any) => {
        const { files: newFiles } = e?.target;

        if (newFiles.length) {
            let updatedFiles = await addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb && callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (fileName: any) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    };

    return (
        <CardWrapper {...props}>
            <Card variant="outlined" style={{ margin: '15px 0', borderColor: '#c4c4c4' }}>
                {(multiple || !hasFiles) && !hasPreview && !disabled && (
                    <>
                        <div style={{ padding: '0 15px' }}>
                            <Typography variant="button" style={{ marginTop: 12 }} display="block">
                                {labelToUpload}
                            </Typography>
                        </div>

                        <FileUploadContainer>
                            <Button variant="contained" onClick={handleUploadBtnClick}>
                                <span> Загрузить {label}</span>
                            </Button>
                            <FormField type="file"
                                       accept={accept}
                                       ref={fileInputField}
                                       onChange={handleNewFileUpload}
                                       title=""
                                       value=""/>
                        </FileUploadContainer>
                    </>
                )}

                {!hasFiles && !hasPreview && (
                    <div style={{ padding: !disabled && children ? '15px' : '10px' }}>
                        {!disabled && children && (
                            <Divider>
                                <span>или</span>
                            </Divider>
                        )}
                        {children}
                    </div>
                )}

                {hasFiles ? (
                    <FilePreviewContainer>
                        <Typography variant="button" style={{ marginTop: 12 }} display="block">
                            {labelUploaded}
                        </Typography>
                        <Card variant="outlined" style={{ margin: '15px 0' }}>
                            <PreviewList>
                                {Object.keys(files).map((fileName, index) => {
                                    let file = files[fileName];
                                    let isImageFile = file.type.split('/')[0] === 'image';
                                    let isVideoFile = file.type.split('/')[0] === 'video';

                                    return (
                                        <PreviewContainer key={fileName}>
                                            <RemoveFileIcon onClick={() => removeFile(fileName)}>
                                                ✕
                                            </RemoveFileIcon>
                                            <div>
                                                {isImageFile && (
                                                    <ImagePreview src={URL.createObjectURL(file)}
                                                                  alt={`file preview ${index}`}/>
                                                )}
                                                {isVideoFile && (
                                                    <video src={URL.createObjectURL(file)}
                                                           className={classes.media}
                                                           autoPlay={false}
                                                           playsInline={true}
                                                           muted={true}
                                                           style={{ margin: 0 }}
                                                           disablePictureInPicture={true}
                                                           controls={true}/>
                                                )}
                                                <FileMetaData isImageFile={isImageFile}>
                                                    <span title={file.name}>{file.name}</span>
                                                    <aside>
                                                        <span>{convertBytes(file.size)}</span>

                                                    </aside>
                                                </FileMetaData>
                                            </div>
                                        </PreviewContainer>
                                    );
                                })}
                            </PreviewList>
                        </Card>
                    </FilePreviewContainer>
                ) : <></>}

                {!hasFiles && preview && (
                    <FilePreviewContainer>
                        <Typography variant="button" style={{ marginTop: 12 }} display="block">
                            {labelUploaded}
                        </Typography>
                        <Card variant="outlined" style={{ margin: '15px 0' }}>
                            <PreviewList>
                                <PreviewContainer>
                                    {hasPreview && <RemoveFileIcon style={{ background: 'green' }}
                                                                   onClick={() => setHasPreview(false)}>
                                        <EditIcon style={{ fontSize: 12 }}/>
                                    </RemoveFileIcon>}
                                    {preview}
                                </PreviewContainer>
                            </PreviewList>
                        </Card>
                    </FilePreviewContainer>
                )}
            </Card>
        </CardWrapper>
    );
};
