import styled from 'styled-components';


export const TreeWrapper = styled.section`
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .item {
    padding: 2px 10px;
    border: 0.03em solid #526e7a;
    background-color: #f4f6f8;
    margin-bottom: 6px;
    width: fit-content;
    border-radius: 6px;
  }

  .description {
    display: block;
    font-size: 12px
  }

  ul li {
    margin-left: 15px;
    position: relative;
    padding-left: 5px;
  }

  ul li::before {
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #000;
    top: 5px;
    bottom: -18px;
    left: -10px;
  }

  body > ul > li:first-child::before {
    top: 12px;
  }

  ul li:not(:first-child):last-child::before {
    display: none;
  }

  ul li:only-child::before {
    display: list-item;
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #000;
    top: 5px;
    bottom: 7px;
    height: 7px;
    left: -10px;
  }

  ul li::after {
    content: " ";
    position: absolute;
    left: -10px;
    width: 10px;
    height: 1px;
    background-color: #000;
    top: 12px;
  }
`;
