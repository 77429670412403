import { useEffect, useLayoutEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom'
import plural from 'plural-ru';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { splitByDecimal } from '../../helpers';
import { PageBG } from '../../theme/useGlobalStyles';
import { useBalanceWithPolling } from '../../hooks/useBalanceWithPolling';
import { useFuturePayments } from '../../api/ads';
import TopBar from './TopBar';
import Menu from './Menu';
import useUserInfoStore from '../../store/userInfo';
import { UserRole } from '../../api/admins';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: PageBG,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256,
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    },
}));

export default function AppLayout() {
    const classes = useStyles();
    const { userInfo } = useUserInfoStore()
    const [ isMobileNavOpen, setMobileNavOpen ] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();

    const isReturnUrl = searchParams.get('afterPayment') === 'true'
    const isSuccessPayment = searchParams.get('successPayment') === 'true';

    const [balance, isBalanceUpdated] = useBalanceWithPolling(isSuccessPayment)
    const [ { data: { amount: blockedAmount = 0 } = {} }, , loadBlockedAmount ] = useFuturePayments();

    useLayoutEffect(() => {
        const amount = +(searchParams.get('amount') || 0)

        if (isSuccessPayment) {
            amount > 0 && enqueueSnackbar(
                <>Спасибо! Скоро {splitByDecimal(amount)} {plural(amount, 'балл поступит', 'балла поступят', 'баллов поступят')} на баланс</>,
                {
                    persist: true,
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                },
            );
        } else if (isReturnUrl) {
            amount > 0 && enqueueSnackbar(
                <>Спасибо! {splitByDecimal(amount)} {plural(amount, 'балл поступит', 'балла поступят', 'баллов поступят')} на баланс сразу после оплаты счёта</>,
                {
                    persist: true,
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                },
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isSuccessPayment ]);

    useEffect(() => {
        if (isBalanceUpdated) {
            setSearchParams('', { replace: true })
        }
    }, [isBalanceUpdated, setSearchParams])

    useEffect(() => {
        // временный костыль для фикса юзеров без телефона
        if (window.location.pathname !== '/app/verify' && !userInfo?.roles?.includes(UserRole.AGENT)) {
            loadBlockedAmount()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.root}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
            <Menu balance={balance} blockedAmount={blockedAmount} onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen}/>
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    );
};
