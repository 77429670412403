import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../api/admins';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';


export default function AuthForm() {
    const [ twoFA, setTwoFA ] = useState(false);
    const [ auth, authProcessing ] = useAuth((res: any) => {
        if (res.success) {
            Cookies.set('auth:lk', res.data.token);
            window.location.pathname = '/app';
        } else if (res.error?.details === 'OTP required') {
            setTwoFA(true);
        }
    });
    const validators: any = {
        login: Yup.string().max(255).required('Обязательное поле'),
        password: Yup.string().max(30).required('Обязательное поле'),
    };
    if (twoFA) {
        validators.otp = Yup.number().required('Обязательное поле');
    }
    return (
        <Formik initialValues={{
            login: '+7',
            password: '',
            otp: undefined,
        }}
                validationSchema={Yup.object().shape(validators)}
                onSubmit={(values) => auth({ ...values, otp: values.otp ? `${values.otp}` : undefined })}>
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                        <Typography color="textPrimary" variant="h2">
                            Авторизация
                        </Typography>
                    </Box>
                    <TextField error={Boolean(touched.login && errors.login)}
                               fullWidth
                               helperText={touched.login && errors.login}
                               label="Телефон или email"
                               margin="normal"
                               name="login"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               type="text"
                               value={values.login}
                               variant="outlined"
                               disabled={twoFA}/>
                    <TextField error={Boolean(touched.password && errors.password)}
                               fullWidth
                               helperText={touched.password && errors.password}
                               label="Пароль"
                               margin="normal"
                               name="password"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               type="password"
                               value={values.password}
                               variant="outlined"
                               disabled={twoFA}/>
                    {twoFA && (
                        <TextField error={Boolean(touched.otp && errors.otp)}
                                   fullWidth
                                   helperText={touched.otp && errors.otp}
                                   label="OTP код"
                                   margin="normal"
                                   name="otp"
                                   onBlur={handleBlur}
                                   onChange={handleChange}
                                   type="number"
                                   value={values.otp}
                                   variant="outlined"/>
                    )}
                    <Box my={2}>
                        <Button color="primary"
                                disabled={isSubmitting || authProcessing}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained">
                            Войти
                        </Button>
                        <Divider style={{ margin: 20 }}/>
                        <Link to="/signup">
                            <Button color="default" fullWidth size="large" type="submit" variant="contained">
                                Еще нет аккаунта?
                            </Button>
                        </Link>
                    </Box>
                </form>
            )}
        </Formik>
    );
};
