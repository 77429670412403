import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { splitByDecimal } from '../../../helpers';
import { AddMoneyModal } from '../../../modals/AddMoneyModal';


const useStyles = makeStyles({
    root: {
        margin: 15,
        marginBottom: -8,
        minHeight: 210
    },
    title: {
        fontSize: 14,
        marginTop: 0,
    },
    blockedTitle: {
        fontSize: 14,
        marginTop: 6,
    },
    subtitle: {
        fontSize: 11,
        marginTop: 5,
    },
    button: {
        margin: '10px 0 35px',
        width: '100%',
    },
});

export const BalanceItem = ({ balance = 0, blockedAmount = 0, shouldShowAddBalance = true }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} variant="elevation">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Ваш баланс в баллах:
                </Typography>

                <Typography variant="h3" component="h2">
                    {splitByDecimal(balance || 0)}
                </Typography>

                <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
                    Обновляется в 24:00 МСК
                </Typography>

                <Typography className={classes.blockedTitle} color="textSecondary" gutterBottom>
                    Заблокировано средств:
                </Typography>

                <Typography variant="h3" component="h2">
                    {splitByDecimal(blockedAmount)}
                </Typography>
                {shouldShowAddBalance && (
                    <AddMoneyModal>
                        <Button className={classes.button}
                                startIcon={<AddIcon/>}
                                size="small"
                                variant="contained"
                                color="primary">
                            Пополнить баланс {' '}
                        </Button>
                    </AddMoneyModal>
                )}
            </CardContent>
        </Card>
    );
};
