import Api, { useFetchApi, useUpdateApi } from './index';


export function useBankPayment(cb: any) {
    return useUpdateApi(
        async (data: any) => Api.post('/checkout/bank', data),
        cb,
    );
}

export function useTopUpMyBalance(cb: any) {
    return useUpdateApi(
        async (data: any) => Api.post('/checkout/create', data),
        cb,
    );
}

export function useWalletList() {
    return useFetchApi(
        async () => Api.get('/user/wallet/list'),
        { items: [] },
    );
}
