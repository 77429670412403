import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Field } from 'react-final-form';


interface ISelectOption {
    value: any;
    label: any;
    className?: any;
}

interface ISelectProps {
    input: any;
    label: string;
    helperText?: string;
    fullWidth?: boolean;
    margin?: 'none' | 'dense' | 'normal';
    options: ISelectOption[];
    meta: any;
    addEmpty?: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        position: 'relative',
        paddingLeft: 30,
    },
    check: {
        position: 'absolute',
        left: 7,
        top: 5,
    },
    selectAll: {
        color: theme.palette.success.main,
    },
    selectNone: {
        color: theme.palette.error.main,
    },
    apply: {
        paddingRight: 30,
        paddingTop: theme.spacing(1),
        textAlign: 'right',
    },
    input: {
        minHeight: 40,
    },
}));

const SelectWrapper = (props: ISelectProps) => {
    const inputLabel = useRef(null);
    const classes = useStyles();
    const {
        input: {
            name, value, onChange, ...restInput
        },
        label,
        helperText,
        fullWidth,
        margin,
        options,
        meta,
        addEmpty,
        ...rest
    } = props;
    const [ labelWidth, setLabelWidth ] = React.useState(label.length * 6);
    const [ open, setOpen ] = useState(false);
    useEffect(() => {
        // @ts-ignore
        setLabelWidth(inputLabel.current.offsetWidth || label.length * 6);
    });
    const opts = [ ...options ];
    if (props.addEmpty !== false) opts.unshift({ value: '', label: <>&nbsp;</> });
    return (
        <FormControl variant="outlined" fullWidth={fullWidth} margin={margin} error={meta.touched && !meta.valid}>
            <InputLabel htmlFor={name} ref={inputLabel}>{label}</InputLabel>
            <Select name={name}
                    inputProps={restInput}
                    value={value === true ? 1 : value === false ? 2 : ''}
                    renderValue={(val: any) => <>{options.find((o) => o.value === val)?.label}</>}
                    onChange={(e) => {
                        const v = e.target.value;
                        onChange(v === 1 ? true : v === 2 ? false : undefined);
                    }}
                // labelWidth={labelWidth}
                    fullWidth
                    input={<OutlinedInput labelWidth={labelWidth} className={classes.input}/>}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    {...rest}
            >
                {opts.map((o) => (
                    <MenuItem value={o.value} key={o.value} className={clsx(classes.item, o.className)}>
                        {o.label}
                    </MenuItem>
                ))}
            </Select>
            {(helperText || (meta.touched && !meta.valid)) &&
            <FormHelperText>{meta.error || helperText}</FormHelperText>}
        </FormControl>
    );
};

interface ISelectFieldProps {
    name: string;
    label: string;
    helperText?: string;
    validate?: any;
    inputRef?: any;
    fullWidth?: boolean;
    margin?: 'none' | 'dense' | 'normal';
    addEmpty?: boolean;
    disabled?: boolean;
}

export default function SelectField({ name, ...rest }: ISelectFieldProps) {

    return (
        <Field name={name}
            // @ts-ignore
               component={SelectWrapper}
               IconComponent={ArrowDropDownIcon}
               options={[ { value: 1, label: 'Да' }, { value: 2, label: 'Нет' } ]}
               {...rest}
        />
    );
}
