import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


interface IProps {
    total?: number,
    average?: number,
    count?: number,
}


const useStyles = makeStyles({
    root: {
        width: 'calc(100% / 3 - 10px)',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
});

export default function AdAnalyticsCards(props: IProps) {
    const classes = useStyles();

    const { count = 0, total = 0, average = 0 } = props;

    return (
        <div className="mobile-to-cols" style={{ display: 'flex', marginBottom: 20 }}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Всего показов за период
                    </Typography>
                    <Typography variant="h2" component="h2">
                        {total}
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined" style={{ marginLeft: 20 }}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Среднее по периоду (в разрезе дня)
                    </Typography>
                    <Typography variant="h2" component="h2">
                        {average}
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined" style={{ marginLeft: 20 }}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Дней с показами рекламы (за период)
                    </Typography>
                    <Typography variant="h2" component="h2">
                        {count}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}
