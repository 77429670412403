import _ from 'lodash';
import { IAdvertiser } from './advertisers';
import { IGroup } from './groups';
import Api, { IBaseModel, useFetchApi, useUpdateApi } from './index';


export enum AdFormat {
    FULLSCREEN = 'FULLSCREEN',
    HALFSCREEN = 'HALFSCREEN',
    FEED_1X1 = 'FEED_1X1',
    FEED_1X2 = 'FEED_1X2',
    FEED_2X2 = 'FEED_2X2',
    FEED_3X2 = 'FEED_3X2',
}

export enum AdDuration {
    DURATION_5S = '5S',
    DURATION_15S = '15S',
    DURATION_30S = '30S',
    DURATION_60S = '60S',
}

export const slotSize = {
    [AdDuration.DURATION_5S]: 1,
    [AdDuration.DURATION_15S]: 3,
    [AdDuration.DURATION_30S]: 6,
    [AdDuration.DURATION_60S]: 12,
};

export interface IAdSchedule {
    id?: number;
    dateFrom: Date;
    dateTo: Date;
    rushHours: boolean;
    dayHours: boolean;
}

export enum AdStatus {
    DRAFT = 'DRAFT',
    IN_WORK = 'IN_WORK',
    ON_CHECK = 'ON_CHECK',
    READY_TO_PUBLISH = 'READY_TO_PUBLISH',
    ACTIVE = 'ACTIVE',
    UNPUBLISHED = 'UNPUBLISHED',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum AdStatusTranslate {
    DRAFT = 'Черновик',
    IN_WORK = 'В работе',
    ON_CHECK = 'На проверке',
    READY_TO_PUBLISH = 'Готова к публикации',
    ACTIVE = 'Активно',
    UNPUBLISHED = 'Снят с публикации',
    INSUFFICIENT_FUNDS = 'Ожидание оплаты'
}

export type AdStatusEnum = AdStatus;

export interface IAd extends IBaseModel {
    status: AdStatusEnum;
    name: string;
    title?: string;
    shortText?: string;
    mainText?: string;
    video: string;
    image: string;
    feedImage: string;
    schedule: IAdSchedule[];
    format: AdFormat;
    advertiser: IAdvertiser;
    groups: IGroup[];
    active: boolean;
    isPublished: boolean;
    isLegal: boolean;
    deletedAt: Date;
    showDuration: AdDuration;
    editable: (keyof IAd | '*')[];
    commentFromAdmin: string;
    commentFromAdvertiser: string;
    commentFileFromAdvertiser: string;
    costPerDay: number;
}

export interface IAdsListRequest {
    name?: string;
    updatedAfter?: Date;
    updatedBefore?: Date;
    active?: boolean;
    status?: boolean;
    groups?: number[];
    advertiser?: number[];
}

export interface IAdsAnalyticsListRequest {
    periodFrom?: string;
    periodTo?: string;
    kioskId?: number;
    adId?: number;
    groupByAdId?: boolean;
}

export interface IAdChangeStatusRequest {
    adId: IAd['id'],
    statusSet: IAd['status']
}

export function useAdsList() {
    return useFetchApi(
        async (query: IAdsListRequest) => Api.post('/paid/list', query),
        { items: [] },
    );
}

export function useFuturePayments() {
    return useFetchApi(
        async () => Api.get('/paid/calculateFuturePayments', {}),
        { data: { amount: 0 } },
        true,
    );
}

export function useAdCreate(cb: any) {
    return useUpdateApi(
        async (data: IAd) => Api.post('/paid/create', data),
        cb,
    );
}

export function useAdUpdate(cb: any) {
    return useUpdateApi(
        async (data: IAd) => Api.post('/paid/update', data),
        cb,
    );
}

export function useAdDelete(cb: any) {
    return useUpdateApi(
        async (id: any) => Api.post('/paid/delete', { id }),
        cb,
    );
}

export function useAdAnalyticsList() {
    return useFetchApi(
        async (data: IAdsAnalyticsListRequest) => Api.post('/paid/analytics', data),
        { items: [] },
        true,
    );
}

export function useAdChangeStatus(cb: any) {
    return useUpdateApi(
        async (data: IAdChangeStatusRequest) => Api.post('/paid/changeAdStatus', data),
        cb,
    );
}

export const formMediaData = (files: any, id: number) => {
    const data = new FormData();

    data.append('id', String(id));

    _.each(files, (value, name) => {
        if (value?.[0]) {
            data.append(name, value[0]);
            data.append(`${name}-meta`, JSON.stringify(value[0]?.meta));
        }
    });

    return data;
};

export const uploadAdMedia = async (filesToUpload: any, item: IAd) => {
    const formData = formMediaData(filesToUpload, item.id);

    return Api.put('/paid/upload/media', formData);
};

export const loadVideo = (file: File) => new Promise<HTMLVideoElement>((resolve, reject) => {
    try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
            resolve(this as HTMLVideoElement);
        };

        video.onerror = function () {
            reject('Invalid video. Please select a video file.');
        };

        video.src = window.URL.createObjectURL(file);
    } catch (e) {
        reject(e);
    }
});

export const loadImage = (file: File): Promise<HTMLImageElement> => {
    const img = document.createElement('img');

    const promise = new Promise((resolve, reject) => {
        img.onload = () => {
            resolve(img);
        };

        img.onerror = reject;
    });

    img.src = URL.createObjectURL(file);

    return promise as Promise<HTMLImageElement>;
};
