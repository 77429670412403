import { useState } from 'react';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';


export default function useFilter(defaultFilter: any): [ (filter: any) => void, any ] {
    const initial = defaultFilter;
    if (defaultFilter.dateTo) {
        initial.dateTo = defaultFilter.dateTo;
    }
    const [ filter, setFilter ] = useState(initial);

    function setFilterState(f: any) {
        const tmp: any = {};
        Object.keys(f).forEach((k) => {
            if (f[k] && ((isNaN(f[k]) || Array.isArray(f[k])) && f[k].length === 0)) {
                tmp[k] = undefined;
            } else {
                tmp[k] = f[k];
            }
        });
        const upd = { ...filter, ...tmp };
        if (upd.updatedAfter) {
            upd.updatedAfter = startOfDay(upd.updatedAfter);
        }
        if (upd.updatedBefore) {
            upd.updatedBefore = endOfDay(upd.updatedBefore);
        }
        setFilter(upd);
    }

    return [ setFilterState, filter ];
}
