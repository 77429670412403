import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from '../../components/Logo';


const useStyles = makeStyles(({
    root: {},
    toolbar: {
        height: 64,
    },
}));

interface IProps {
    className?: any;
}

export default function TopBar({ className, ...rest }: IProps) {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} elevation={0}
                {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/">
                    <Logo/>
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
};
