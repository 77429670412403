import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useSignup } from '../../../api/admins';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Countdown from 'react-countdown';


export default function Signup() {
    const [ useSmsCode, setUseSmsCode ] = useState(false);
    const [ resendTime, setResentTime ] = useState(moment());

    const [ signup, signupProcessing ] = useSignup((res: any) => {
        if (res.success) {
            Cookies.set('auth:lk', res.data.token);
            window.location.pathname = !useSmsCode ? 'app/verify' : 'app';
        } else if (res.error?.details === 'no-sms-code') {
            setUseSmsCode(true);
            setResentTime(moment().add('3', 'minute'));
        }
    });

    const validators: any = {
        login: Yup.string().max(255).required('Обязательное поле'),
        password: Yup.string().max(30).required('Обязательное поле'),
    };

    return (
        <Formik initialValues={{
            login: '+7',
            password: '',
            smsCode: '',
            referral: Cookies.get('ref'),
            isAgent: Cookies.get('agentSignup')
        }} validationSchema={Yup.object().shape(validators)} onSubmit={(values) => signup({ ...values })}>
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setValues,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                        <Typography color="textPrimary" variant="h2">
                            Регистрация
                        </Typography>
                    </Box>
                    <TextField error={Boolean(touched.login && errors.login)}
                               fullWidth
                               helperText={touched.login && errors.login}
                               label="Телефон"
                               margin="normal"
                               name="login"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               type="text"
                               value={values.login}
                               disabled={useSmsCode}
                               variant="outlined"/>
                    <TextField error={Boolean(touched.password && errors.password)}
                               fullWidth
                               helperText={touched.password && errors.password}
                               label="Пароль"
                               margin="normal"
                               name="password"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               type="password"
                               value={values.password}
                               variant="outlined"/>
                    {useSmsCode && (
                        <TextField error={Boolean(touched.smsCode && errors.smsCode)}
                                   fullWidth
                                   helperText={touched.smsCode && errors.smsCode}
                                   label="SMS код"
                                   margin="normal"
                                   name="smsCode"
                                   onBlur={handleBlur}
                                   onChange={handleChange}
                                   type="text"
                                   value={values.smsCode}
                                   variant="outlined"/>
                    )}

                    {
                        useSmsCode && (
                            <div style={{ textAlign: 'center' }}>
                                Введите <b>4 последние цифры</b> от поступившего звонка </div>
                        )
                    }

                    {
                        useSmsCode && (
                            <Countdown autoStart date={resendTime.toDate()} renderer={({ completed, formatted }) => (
                                <Button fullWidth disabled={!completed} onClick={() => {
                                    if (!completed) {
                                        return;
                                    }
                                    setValues({ ...values, smsCode: '' });
                                    handleSubmit();
                                }} style={{ textAlign: 'center', cursor: 'pointer', margin: '20px 0' }}>
                                    Повторить отправку
                                    {/*{completed ? '' : <>через {formatted.minutes}:{formatted.seconds}</>}*/}
                                </Button>
                            )}/>
                        )
                    }

                    <Box my={2}>
                        <Button color="primary"
                                disabled={isSubmitting || signupProcessing}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained">
                            Регистрация
                        </Button>
                        <Divider style={{ margin: 20 }}/>
                        <Link to="/">
                            <Button color="default" fullWidth size="large" type="submit" variant="contained">
                                Уже есть аккаунт?
                            </Button>
                        </Link>
                    </Box>
                </form>
            )}
        </Formik>
    );
};
