import React from 'react';
import { IAdvertisersListRequest } from '../../api/advertisers';


export const defaultFilter: IAdvertisersListRequest = {
    name: undefined,
    email: undefined,
    active: undefined,
};

interface IProps {
    filter: IAdvertisersListRequest;
    onApply: any;
}

export default function WalletFilter({ filter, onApply }: IProps) {
    return (
        <></>
        // <Filters initial={filter} onSubmit={onApply}>
        //     <Input name="name" label="Название" margin="dense"/>
        //     <Input name="email" label="Email" margin="dense"/>
        //     <BoolSelect name="email" label="Активен" margin="dense"/>
        // </Filters>
    );
}
