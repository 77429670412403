import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IGroup } from '../../api/groups';
import Popup from '../../components/Popup';
import { BoolSelect, Input, Select } from '../../components/Form';
import { IBoard, useBoardCreate, useBoardUpdate } from '../../api/boards';
import { IKiosk } from '../../api/kiosks';
import { required } from '../../validators';
import { BoardDeviceInfo } from './BoardDeviceInfo';
import { BoardNetworkInfo } from './BoardNetworkInfo';


interface IProps {
    board: IBoard;
    onSave: any;
    onClose: any;
    kiosks: IKiosk[];
    groups: IGroup[];
}

export default function BoardDetails({ board, kiosks, groups, onSave, onClose }: IProps) {
    const useSave = board.id ? useBoardUpdate : useBoardCreate;

    const [ save, saving ] = useSave((res: any) => {
        if (res.success) {
            onSave();
            if (!board.id) {
                onClose();
            }
        }
    });

    const initial = {
        ...board,
        kiosk: board.kiosk?.id,
        groups: board.groups?.filter((g) => !g.internal).map((g) => g.id),
    };

    return (
        <Popup onClose={onClose} title={board.name || 'Добавить панель'}>
            <Form onSubmit={(values) => save({
                ...values,
                kiosk: kiosks.find((k) => k.id === values.kiosk),
                groups: values.groups.map((id: number) => ({ id })),
                id: board.id,
            })} initialValues={initial} render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Input name="name" label="Имя" validate={required} margin="normal"/>
                    <Input name="title" label="Название" validate={required} margin="normal"/>
                    <Select name="kiosk"
                            label="Киоск"
                            options={kiosks?.map((k) => ({ value: k.id, label: k.name }))}
                            validate={required}
                            fullWidth
                            margin="normal"/>
                    <Select name="version"
                            label="Версия сайта"
                            options={[ { value: 1, label: 'Версия 1' }, { value: 2, label: 'Версия 2' } ]}
                            validate={required}
                            fullWidth
                            margin="normal"/>
                    <Select name="groups"
                            label="Группы"
                        // @ts-ignore
                            options={groups?.filter((g) => !g.internal).map((g) => ({
                                value: g.id,
                                label: g.name
                            }))}
                            fullWidth
                            margin="normal"
                            multi/>
                    <BoolSelect name="active" label="Активна" margin="normal" addEmpty={false} fullWidth={true}/>
                    <Box my={2}>
                        <Button type="submit" variant="contained" color="primary" size="large" disabled={saving}>
                            Сохранить
                        </Button>
                    </Box>
                </form>
            )}/>

            {board.deviceInfo && <BoardDeviceInfo board={board}/>}

            <br/>

            {board.networkInfo && <BoardNetworkInfo board={board}/>}
        </Popup>
    );
}
