import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import BoolChip from '../../components/UI/BoolChip';
import DateView from '../../components/DateView';
import { AdStatusTranslate, IAd } from '../../api/ads';
import { AdStatusIcon } from '../../components/UI/AdStatusIcon';
import { Tooltip } from '@material-ui/core';
import { getDaysDuration } from '../../helpers/FormatMapper';


interface IProps {
    ads: IAd[];
    onRowClick: any;
}

export default function AdsList({ ads, onRowClick }: IProps) {

    return (
        <Card>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id рекламы</TableCell>
                                <TableCell>Заголовок</TableCell>
                                <TableCell>Расписание</TableCell>
                                <TableCell>Стоимость (1 день)</TableCell>
                                <TableCell>Статус</TableCell>
                                {/*<TableCell>Активна</TableCell>*/}
                                <TableCell>Добавлена</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ads.map((a) => (
                                <TableRow hover key={a.id} onClick={() => onRowClick(a.id)}>
                                    <TableCell>
                                        {a.id}
                                        {a.extId ? <span style={{ fontSize: 10 }}><br/>#{a.extId}</span> : ''}
                                    </TableCell>
                                    <TableCell>{a.name}</TableCell>
                                    <TableCell>
                                        {a.schedule.map((s, i) => (
                                            <div key={i}>
                                                {new Date(s.dateFrom).toDateString() === new Date(s.dateTo).toDateString() ?
                                                    (<DateView value={s.dateFrom} withTime={false}/>) :
                                                    (<>
                                                        <DateView value={s.dateFrom} withTime={false}/> - <DateView
                                                        value={s.dateTo}
                                                        withTime={false}/>
                                                    </>)}
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        {(() => {
                                            const days = a.schedule.length && getDaysDuration(a.schedule[0].dateFrom, a.schedule[0].dateTo);

                                            return (
                                                <Tooltip title={a.schedule.length
                                                    ? <>{days} дн.<br/>Итого: {a.costPerDay * days} баллов</>
                                                    : ''} placement="top">
                                                    <span>{a.costPerDay}</span>
                                                </Tooltip>
                                            );
                                        })()}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={`${AdStatusTranslate[a.status]}${a.active ? ', сейчас в ротации' : ''}`}
                                                 placement="top">
                                            <AdStatusIcon active={a.active} status={a.status}/>
                                        </Tooltip>
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*  <BoolChip value={a.active}/>*/}
                                    {/*</TableCell>*/}
                                    <TableCell>
                                        <DateView value={a.createdAt}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};
