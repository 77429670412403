import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddReferralModal from '../../../views/Referral/AddReferralModal';


const useStyles = makeStyles({
    root: {
        margin: 15,
        marginBottom: 8,
        minHeight: 140,
    },
    title: {
        fontSize: 14,
        marginTop: 0,
    },
    subtitle: {
        fontSize: 11,
        marginTop: 5,
    },
    button: {
        margin: '10px 0 10px',
        width: '100%',
    },
});

export const ReferralLink = ({ id = 0 }) => {
    const classes = useStyles();
    const [isReferralModalOpen, setIsRefferalModalOpen] = useState(false)

    return (
        <Card className={classes.root} variant="elevation">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Реферальная программа
                </Typography>

                <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
                    Получайте бонусы за привлечение пользователей
                </Typography>

                <Button className={classes.button}
                        onClick={() => setIsRefferalModalOpen(true)}
                        size="small"
                        variant="contained"
                        color="primary"
                >
                    Копировать ссылку
                </Button>
                <AddReferralModal
                    userId={id}
                    open={isReferralModalOpen}
                    onClose={() => setIsRefferalModalOpen(false)}
                />
            </CardContent>
        </Card>
    );
};
