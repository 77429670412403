import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import AdminsList from './AdminsList';
import AdminDetails from './AdminDetails';
import AdminsFilter, { defaultFilter } from './AdminsFilter';
import { IAdminCreateRequest, useAdminsList } from '../../api/admins';
import useFilter from '../../hooks/useFilter';


const emptyAdmin: IAdminCreateRequest = {
    email: '',
    password: '',
    secret: '',
    roles: [],
};

export default function AdminsPage() {
    const { id } = useParams();
    const [ { items }, loading, load ] = useAdminsList();
    const [ setFilter, filter ] = useFilter(defaultFilter);
    const [ admin, setAdmin ] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        load(filter);
    }, [ filter ]);

    useEffect(() => {
        if (id) {
            if (id === 'new') {
                setAdmin(emptyAdmin);
            } else if (items.length) {
                setAdmin(items.find((i: any) => i.id === parseInt(id)));
            }
        } else {
            setAdmin(undefined);
        }
    }, [ id, items ]);

    return (
        <Page title="Пользователи"
              action={<Button variant="contained" color="primary" onClick={() => navigate('/app/admins/new')}>Новый
                  пользователь</Button>}>
            <AdminsFilter filter={filter} onApply={setFilter}/>
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Loader loading={loading}>
                        <AdminsList users={items} onRowClick={(id: string) => navigate(`/app/admins/${id}`)}/>
                    </Loader>
                </Box>
            </Container>
            {admin && (
                <AdminDetails admin={admin} onSave={() => load(filter)} onClose={() => navigate('/app/admins')}/>
            )}
        </Page>
    );
};
