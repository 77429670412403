import { makeStyles } from '@material-ui/core';
import { PageBG } from '../../../theme/useGlobalStyles';
import TopBarSkeleton from './TopBar';
import MenuSkeleton from './Menu';
import { Skeleton } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: PageBG,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256,
        },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    },
}));

export default function AppLayoutSkeleton() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopBarSkeleton />
            <MenuSkeleton />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Skeleton height="100%" variant="rectangular" sx={{ bgcolor: '#e9e9e9' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
