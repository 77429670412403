import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


interface IProps {
    advertisers: any;
}

// TODO very bad, get typescript types and enums
const getPaymentName = (payment: any) => {
    if (payment.type === 'LEGAL') {
        return ` • Инвойсбокс`
    }

    return ` • ЮКасса`
}

export default function WalletList({ advertisers }: IProps) {

    return (
        <Card>
            <PerfectScrollbar>
                <Box minWidth={1050}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Вид</TableCell>
                                <TableCell>Сумма</TableCell>
                                <TableCell>Дата</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {advertisers.map((a: any) => (
                                <TableRow hover key={a.id}>
                                    <TableCell>#{a.id}</TableCell>
                                    <TableCell>
                                        {a.amount > 0 ? 'Пополнение' : 'Списание' }{a.payment ? getPaymentName(a.payment) : ''}
                                    </TableCell>
                                    <TableCell>
                                        {a.amount}
                                    </TableCell>
                                    <TableCell>
                                        {moment(a.createdAt).format('LL в HH:MM')}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};
