import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/InsertLink';
import { Fade, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useCopyToClipboard from '../../hooks/useCopy';

interface IProps {
  onClose: () => void;
  open: boolean;
  userId: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 6,
            boxShadow: theme.shadows[5],
            padding: '32px 30px',
            minWidth: 400,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        subtitle: {
          fontSize: 13,
          marginTop: 10,
      },
      buttons: {
        marginTop: 24,
        display: 'flex',
        width: 'calc(100% - 8px)',
        flexDirection: 'column',
        gap: 16
      }
    }),
);

export default function AddReferralModal({ userId, onClose, open }: IProps) {
  const classes = useStyles();
  const [linkCopy, copyLink] = useCopyToClipboard()
  const [agentCopy, copyAgentLink] = useCopyToClipboard()

  const link = `https://${window.location.hostname}/signup?ref=${userId}`
  const agentLink = `${link}&agent=true`

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      title="Реферальная программа"
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography variant="h3" component="h2">
              Реферальная программа
          </Typography>

          <Typography className={classes.subtitle} component="h2">
              Скопируйте нужную ссылку
          </Typography>

          <div className={classes.buttons}>
            <Button
              onClick={() => copyLink(link, 3000)}
              startIcon={<AddIcon/>}
              size="small"
              variant="contained"
              color="primary"
            >
              {linkCopy ? 'Скопировано' : 'Пригласить другого рекламодателя'}
            </Button>
            <Button
              onClick={() => copyAgentLink(agentLink, 3000)}
              startIcon={<AddIcon/>}
              size="small"
              variant="contained"
              color="primary"
            >
              {agentCopy ? 'Скопировано' : 'Пригласить рекламного агента'}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}