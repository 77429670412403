import React from 'react';
import format from 'date-fns/format';


interface IDateViewProps {
    value: Date | number | string;
    withTime?: boolean;
}

export default function DateView({ value, withTime = true }: IDateViewProps) {
    const d = new Date(value);
    return <>{format(d, `dd.MM.yyyy${withTime ? ' HH:ii:ss' : ''}`)}</>;
}
