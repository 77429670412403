import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from '../../../components/Logo';
import { Skeleton } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    buttons: {
      display: 'flex',
      gap: 16,
      alignItems: 'center'
    }
}));

export default function TopBarSkeleton() {
    const classes = useStyles();

    return (
        <AppBar elevation={0}>
            <Toolbar>
                <div>
                    <Logo/>
                </div>
                <Box flexGrow={1}/>
                <Hidden mdDown>
                    <div className={classes.buttons}>
                      <Skeleton sx={{ bgcolor: '#d2d2d2' }} variant="text" width={95} height={22} />
                      <Skeleton sx={{ bgcolor: '#d2d2d2' }} variant="circular" width={32} height={32} />
                      <Skeleton sx={{ bgcolor: '#d2d2d2' }} variant="rectangular" width={24} height={24} />
                    </div>
                </Hidden>
                <Hidden lgUp>
                  <Skeleton sx={{ bgcolor: '#d2d2d2' }} variant="rectangular" width={24} height={24} />
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};
