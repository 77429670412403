import Api, { AdminApi, useFetchApi, useUpdateApi } from './index';
import { IBoard } from './boards';


export interface IKiosk {
    id?: number;
    name: string;
    address?: string;
    askGlonassId?: string;
    description?: string;
    controllerIp?: string;
    boards?: IBoard[];
    active?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

export interface IKiosksListRequest {
    name?: string;
    updatedAfter?: Date;
    updatedBefore?: Date;
    active?: boolean;
}

export function useKiosksList() {
    return useFetchApi(
        async (query: any) => AdminApi.post('/kiosk/list', query),
        { items: [] },
        true,
    );
}

export function useKioskCreate(cb: any) {
    return useUpdateApi(
        async (data: IKiosk) => Api.post('/kiosk/create', data),
        cb,
    );
}

export function useKioskUpdate(cb: any) {
    return useUpdateApi(
        async (data: IKiosk) => Api.post('/kiosk/update', data),
        cb,
    );
}
