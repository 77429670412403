import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { Field } from 'react-final-form';


interface ISelectOption {
    value: any;
    label: any;
    className?: any;
}

interface ISelectProps {
    input: any;
    label: string;
    helperText?: string;
    fullWidth?: boolean;
    margin?: 'none' | 'dense' | 'normal';
    options: ISelectOption[];
    meta: any;
    loading?: boolean;
    noEmptyOption?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        position: 'relative',
        paddingLeft: 30,
    },
    check: {
        position: 'absolute',
        left: 7,
        top: 5,
    },
    selectAll: {
        color: theme.palette.success.main,
    },
    selectNone: {
        color: theme.palette.error.main,
    },
    apply: {
        paddingRight: 30,
        paddingTop: theme.spacing(1),
        textAlign: 'right',
    },
    input: {
        minHeight: 40,
    },
}));

const SelectWrapper = (props: ISelectProps) => {
    const inputLabel = useRef(null);
    const classes = useStyles();
    const {
        input: {
            name, value, onChange, multiple, ...restInput
        },
        label,
        helperText,
        fullWidth,
        margin,
        options,
        meta,
        loading,
        noEmptyOption,
        ...rest
    } = props;
    const [ labelWidth, setLabelWidth ] = React.useState(label.length * 6);
    const [ open, setOpen ] = useState(false);
    useEffect(() => {
        // @ts-ignore
        setLabelWidth(inputLabel.current.offsetWidth || label.length * 6);
    });
    let opts = [ ...options ];
    if (multiple) {
        opts.unshift(
            { value: 'SELECT_ALL', label: 'Выбрать все', className: classes.selectAll },
            { value: 'SELECT_NONE', label: 'Очистить', className: classes.selectNone },
        );
    } else if (!noEmptyOption) {
        opts.unshift({ value: '', label: <>&nbsp;</> });
    }
    return (
        <FormControl variant="outlined" fullWidth={fullWidth} margin={margin} error={meta.touched && !meta.valid}>
            <InputLabel htmlFor={name} ref={inputLabel}>{label}</InputLabel>
            <Select name={name}
                    inputProps={restInput}
                    value={loading ? null : (multiple && !Array.isArray(value) ? [ value ] : value)}
                    renderValue={(val: any) => {
                        if (Array.isArray(val)) {
                            const arr = val.map((v) => options.find((o) => o.value === v)?.label);
                            return <>{arr.join(', ')}</>;
                        }
                        return <>{options.find((o) => o.value === val)?.label}</>;
                    }}
                    onChange={(e) => {
                        if (multiple && Array.isArray(e.target.value)) {
                            if (e.target.value.includes('SELECT_ALL')) {
                                return onChange(options.map((o) => o.value));
                            } else if (e.target.value.includes('SELECT_NONE')) {
                                return onChange([]);
                            }
                            return onChange(e.target.value.filter((v) => !!v));
                        }
                        onChange(e.target.value);
                    }}
                // labelWidth={labelWidth}
                    fullWidth
                    input={<OutlinedInput labelWidth={labelWidth} className={classes.input}/>}
                    multiple={multiple}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    {...rest}
            >
                {opts.map((o) => <MenuItem value={o.value} key={o.value} className={clsx(classes.item, o.className)}>
                    {multiple && value.includes(o.value) && <CheckIcon className={classes.check}/>} {o.label}
                </MenuItem>)}
                {multiple && (
                    <div className={classes.apply}>
                        <Button size="small" variant="contained" onClick={() => setOpen(false)}>Применить</Button>
                    </div>
                )}
            </Select>
            {(helperText || (meta.touched && !meta.valid)) &&
            <FormHelperText>{meta.error || helperText}</FormHelperText>}
        </FormControl>
    );
};

interface ISelectFieldProps {
    name: string;
    label: string;
    options: ISelectOption[];
    helperText?: string;
    validate?: any;
    disabled?: boolean;
    loading?: boolean;
    inputRef?: any;
    fullWidth?: boolean;
    multi?: boolean;
    margin?: 'none' | 'dense' | 'normal';
    noEmptyOption?: boolean;
    defaultValue?: any;
}

function Loading() {
    return <CircularProgress size={20} style={{ marginRight: 10 }}/>;
}

export default function SelectField({ name, loading, disabled, multi, options, ...rest }: ISelectFieldProps) {
    return (
        <Field name={name}
            // @ts-ignore
               component={SelectWrapper}
               IconComponent={loading ? Loading : disabled ? () => null : ArrowDropDownIcon}
               multiple={multi}
               options={options}
               disabled={disabled}
               {...rest}
        />
    );
}
