import _ from 'lodash';
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import { useGetMyReferralGraph } from '../../api/selfSettings';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TreeWrapper } from './styled';
import { Paper } from '@mui/material';
import { splitByDecimal } from '../../helpers';


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    block: {
        width: 'calc(100% / 2 - 30px)',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
});

const RecursiveGraph = ({ graph }: any) => {
    const elements = [];

    if (!graph || !graph.length) {
        return <></>;
    }

    for (const user of graph) {
        if (user.hasOwnProperty('branchSum')) {
            continue;
        }

        const sumUp: any = user.refs ? (_.last(user.refs) as any).branchSum : { sum: 0, cnt: 0 };

        elements.push(
            <li>
                <div className="item">
                    <b>
                        {user.advertiser.name} •
                        <span style={{ fontSize: 12 }}> Самостоятельно
                            оплачено: {splitByDecimal(user.totalPayments || 0)}</span>
                    </b>
                    <span className="description">
                        Email: {user.email} • Привлечено по графу <b>{sumUp?.cnt}</b> на
                        сумму <b>{splitByDecimal(sumUp?.sum || 0)}</b> балл(ов).
                    </span>
                </div>

                {user.refs?.length ? (
                    <ul>
                        <RecursiveGraph key={user.id} graph={user.refs}/>
                    </ul>
                ) : null}
            </li>,
        );
    }

    return (
        <TreeWrapper>
            <ul>
                {elements.map((element) => (<>{element}</>))}
            </ul>
        </TreeWrapper>
    );

};

function ReferralPage() {
    const classes = useStyles();

    const [ { items: graph }, loadingGraph, loadGraph ] = useGetMyReferralGraph();

    useEffect(() => {
        loadGraph();
    }, []);

    const sumUp = graph && (_.last(graph) as any)?.branchSum;

    return (
        <Page className={classes.root} title="Реферальная программа">
            <Container maxWidth={false} style={{ width: '100%' }}>
                <Box mt={3} style={{ width: '100%' }}>
                    <Loader loading={loadingGraph}>

                        <div style={{ display: 'flex' }}>
                            <Card variant="outlined"
                                  className={classes.block}
                                  style={{ display: 'flex', flex: 1, marginRight: 20 }}>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Получено по графу (баллов)
                                    </Typography>
                                    <Typography variant="h2" component="h2">
                                        {splitByDecimal(sumUp?.sum || 0)}
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card variant="outlined" className={classes.block} style={{ display: 'flex', flex: 1 }}>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Привлечено людей по графу
                                    </Typography>
                                    <Typography variant="h2" component="h2">
                                        {splitByDecimal(sumUp?.cnt || 0)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>

                        <Paper style={{ padding: 30, paddingTop: 15, marginTop: 20 }}>
                            {graph.length
                                ? <RecursiveGraph graph={graph}/>
                                : (
                                    <div style={{
                                        display: 'flex',
                                        height: 320,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        У вас пока что нет привлеченных рекламодателей

                                    </div>
                                )
                            }
                        </Paper>

                    </Loader>
                </Box>
            </Container>
        </Page>
    );
}


export default ReferralPage;
