import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { IAd, IAdSchedule, slotSize } from '../../../api/ads';
import { getDaysDuration } from '../../../helpers/FormatMapper';


const useStyles = makeStyles({
    root: {
        marginTop: 30,
        minHeight: 170,
    },
    title: {
        fontSize: 14,
        marginTop: 0,
    },
    subtitle: {
        fontSize: 11,
        marginTop: 5,
    },
    button: {
        margin: '10px 0 35px',
        width: '100%',
    },
});

interface Props {
    ad: IAd;
    schedule: IAdSchedule;
}

export const AdCost = (props: Props) => {
    const classes = useStyles();

    if (!props.schedule) {
        return <></>
    }

    const { costPerDay = 0, showDuration } = props.ad;
    const { rushHours = false, dateFrom, dateTo } = props.schedule;

    return costPerDay > 0 ? (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Коэффициент "час-пик":
                </Typography>
                <Typography variant="h5" component="h2">
                    {rushHours ? 'Да' : 'Нет'}
                </Typography>

                <Divider style={{marginTop: 15, marginBottom: 15}}/>

                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Стоимость показа / день:
                </Typography>
                <Typography variant="h5" component="h2">
                    {costPerDay} б.
                </Typography>

                <Divider style={{marginTop: 15, marginBottom: 15}}/>

                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Слотов (1 слот = 15 сек, настраивается в "Информация")
                </Typography>
                <Typography variant="h5" component="h2">
                    {slotSize[showDuration]}
                </Typography>

                <Divider style={{marginTop: 15, marginBottom: 15}}/>

                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Примерная итоговая стоимость за период
                </Typography>
                <Typography variant="h5" component="h2">
                    {costPerDay * getDaysDuration(dateFrom, dateTo)} б.
                </Typography>

            </CardContent>
        </Card>
    ) : <></>;
};
